import { ERROR, SUCCESS, WARNING } from './toastTypes';
import T from 'ecto-common/lib/lang/Language';
import { ToastType } from 'ecto-common/lib/Toast/ToastContainer';
import EventEmitter from '../utils/EventEmitter';

class ToastStore {
  //
  // Private variables

  _toasts: ToastType[] = [];
  _eventManager: EventEmitter<ToastType[]> = new EventEmitter();
  _timeout = 2500;
  _toastCounter = 0;

  //
  // Public methods
  addErrorToast(message: React.ReactNode) {
    this._addToast(message, ERROR);
  }

  removeToast(toastId: number) {
    this._toasts = this._toasts.filter((toast) => toast.id !== toastId);
    this._notifyListeners();
  }

  addSuccessToast(message: React.ReactNode) {
    this._addToast(message, SUCCESS);
  }

  addWarningToast(message: React.ReactNode) {
    this._addToast(message, WARNING);
  }

  addSuccessToastForUpdatedItem(itemName: string, isNew: boolean) {
    if (isNew) {
      this.addSuccessToast(T.format(T.toast.addaction.successformat, itemName));
    } else {
      this.addSuccessToast(
        T.format(T.toast.editaction.successformat, itemName)
      );
    }
  }

  addSuccessToastForDeletedItem(itemName: string) {
    this.addSuccessToast(
      T.format(T.toast.deleteaction.successformat, itemName)
    );
  }

  addErrorToastForUpdatedItem(itemName: string, isNew: boolean) {
    if (isNew) {
      this.addErrorToast(T.format(T.toast.addaction.failformat, itemName));
    } else {
      this.addErrorToast(T.format(T.toast.editaction.failformat, itemName));
    }
  }

  addErrorToastForDeletedItem(itemName: string) {
    this.addErrorToast(T.format(T.toast.deleteaction.failformat, itemName));
  }

  addListener(fn: (toasts: ToastType[]) => void) {
    this._eventManager.addListener(fn);
  }

  removeListener(fn: (toasts: ToastType[]) => void) {
    this._eventManager.removeListener(fn);
  }

  clear() {
    this._toasts = [];
    this._notifyListeners();
  }

  //
  // Private methods

  _notifyListeners() {
    const toastCopy = this._toasts.slice();

    this._eventManager.emit(toastCopy);
  }

  _addToast(message: React.ReactNode, type: string, timeout = this._timeout) {
    const toastId = this._toastCounter++;

    this._toasts.push({
      id: toastId,
      message,
      type
    });

    this._notifyListeners();

    setTimeout(() => {
      this._toasts = this._toasts.filter((toast) => toast.id !== toastId);

      this._notifyListeners();
    }, timeout);
  }
}

export default ToastStore;
