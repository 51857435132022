import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import _ from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  ModelDefinition,
  ModelDynamicBoolProperty,
  ModelDynamicBoolRootProperty,
  ModelDynamicOptionsProperty,
  ModelDynamicStringProperty,
  ModelFormLineType,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { produce } from 'immer';

export function text<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  key: (path: ObjectType) => string,
  inputLabel: string,
  hasError: ModelDynamicBoolProperty<
    ObjectType,
    EnvironmentType,
    string
  > = false,
  placeholder = inputLabel
): ModelDefinition<ObjectType, EnvironmentType> {
  return {
    key,
    label: inputLabel,
    placeholder,
    modelType: ModelType.TEXT,
    hasError
  };
}

export function disabledText<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  key: (path: ObjectType) => string,
  inputLabel: string
): ModelDefinition<ObjectType, EnvironmentType> {
  return {
    key,
    label: inputLabel,
    modelType: ModelType.TEXT,
    enabled: false
  };
}

export function bool<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  key: (path: ObjectType) => boolean,
  inputLabel: string,
  helpText: string
): ModelDefinition<ObjectType, EnvironmentType> {
  return {
    key,
    label: inputLabel,
    modelType: ModelType.BOOL,
    helpText
  };
}

export function option<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = object
>(
  key: (path: ObjectType) => ValueType,
  inputLabel: string,
  options: ModelDynamicOptionsProperty<ObjectType, EnvironmentType, ValueType>,
  hasError: ModelDynamicBoolProperty<
    ObjectType,
    EnvironmentType,
    ValueType
  > = false,
  onDidUpdate: (
    name: string[],
    value: ValueType,
    input: ObjectType
  ) => [key: (path: ObjectType) => unknown, value: unknown][] = null,
  enabled: ModelDynamicBoolRootProperty<ObjectType, EnvironmentType> = true,
  // use value: identity since we don't use any translation for any option in this model
  helpText: string = null
): ModelDefinition<ObjectType, EnvironmentType, ValueType> {
  return {
    key,
    label: inputLabel,
    options,
    modelType: ModelType.OPTIONS,
    hasError,
    enabled,
    helpText,
    onDidUpdate
  };
}

export function number<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  key: (path: ObjectType) => number,
  inputLabel: string,
  hasError: ModelDynamicBoolProperty<
    ObjectType,
    EnvironmentType,
    number
  > = false,
  step = 1,
  helpText: string = null,
  errorText: ModelDynamicStringProperty<
    ObjectType,
    EnvironmentType,
    number
  > = null
): ModelDefinition<ObjectType, EnvironmentType> {
  return {
    key,
    label: inputLabel,
    placeholder: inputLabel,
    modelType: ModelType.NUMBER,
    helpText,
    hasError,
    errorText,
    step
  };
}

export function signal<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  key: (path: ObjectType) => string,
  inputLabel: string,
  hasError: ModelDynamicBoolProperty<ObjectType, EnvironmentType, string>,
  placeholder = inputLabel
): ModelDefinition<ObjectType, EnvironmentType> {
  return {
    key,
    label: inputLabel,
    placeholder,
    modelType: ModelType.SIGNAL,
    hasError
  };
}

export function label<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  key: (path: ObjectType) => string | number,
  inputLabel: string
): ModelDefinition<ObjectType, EnvironmentType> {
  return {
    key,
    label: inputLabel,
    modelType: ModelType.LABEL
  };
}

export function updateInputItem<ObjectType extends object>(
  item: ObjectType,
  key: string[],
  value: unknown
) {
  const newItem = { ...item };
  _.set(newItem, key, value);
  return newItem;
}

export function useUpdateModelFormInput<ObjectType extends object>(
  setter: Dispatch<SetStateAction<ObjectType>>
): (key: string[], value: unknown) => void {
  return useCallback(
    (key: string[], value: unknown) => {
      setter((oldItem) => {
        return updateInputItem(oldItem, key, value);
      });
    },
    [setter]
  );
}

function sectionLineToModels<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  line: ModelFormLineType<ObjectType, EnvironmentType>
): ModelDefinition<ObjectType, EnvironmentType>[] {
  if (line.lines == null || line.lines.length === 0) {
    return line.models ?? [];
  }

  return [
    ...(line.models ?? []),
    ..._.flatMap(line.lines, sectionLineToModels)
  ];
}

// Extract all models from the supplied sections

export function modelFormSectionsToModels<ObjectType extends object = object>(
  sections: ModelFormSectionType<ObjectType>[]
): ModelDefinition<ObjectType>[] {
  return _.flatMapDeep(sections, (section) => {
    return (section.models ?? ([] as ModelDefinition<ObjectType>[]))
      .concat(_.flatMap(section.lines, sectionLineToModels))
      .concat(_.flatMapDeep(modelFormSectionsToModels(section.sections)));
  });
}

export type ModelDefinitionWithSection<ObjectType extends object> =
  ModelDefinition<ObjectType> & {
    section: ModelFormSectionType<ObjectType>;
  };

function sectionLineToModelsWithParentSection<ObjectType extends object>(
  section: ModelFormSectionType<ObjectType>,
  line: ModelFormLineType<ObjectType>
): ModelDefinitionWithSection<ObjectType>[] {
  return [
    ..._.map(line.models, (model) => ({ ...model, section })),
    ..._.flatMap(line.lines, (nestedLine) =>
      sectionLineToModelsWithParentSection(section, nestedLine)
    )
  ];
}

// Same as modelFormSectionsToModels, but creates new models with section property set
export function modelFormSectionsToModelsWithParent<ObjectType extends object>(
  sections: ModelFormSectionType<ObjectType>[]
): ModelDefinitionWithSection<ObjectType>[] {
  return _.flatMapDeep(sections, (section) => {
    return [
      ..._.map(section.models, (model) => ({ ...model, section })),
      ..._.flatMap(section.lines, (line) =>
        sectionLineToModelsWithParentSection(section, line)
      ),
      ..._.flatMapDeep(modelFormSectionsToModelsWithParent(section.sections))
    ];
  });
}

export function flattenModelFormSections<ObjectType extends object>(
  sections: ModelFormSectionType<ObjectType>[]
): ModelFormSectionType<ObjectType>[] {
  if (sections == null) {
    return [];
  }

  return [
    ...sections,
    ..._.flatMap(sections, (section) =>
      flattenModelFormSections(section.sections)
    )
  ];
}

type PathFunction<T, Y> = (path: T) => Y;
const fakeObj = {};

export function getPathFromModelKeyFunc<T extends object, Ret>(
  pathFunction: PathFunction<T, Ret>
) {
  const fullPath: string[] = [];
  let proxyInstance: T = null;

  const proxy = new Proxy<T>(fakeObj as T, {
    get: (_formobject: T, path: string) => {
      fullPath.push(path);
      return proxyInstance;
    }
  });

  proxyInstance = proxy;

  pathFunction(proxy);

  return fullPath;
}

export function getPathStringFromModelKeyFunc<T extends object, Ret>(
  pathFunction: PathFunction<T, Ret>
) {
  if (pathFunction == null) {
    return null;
  }

  return getPathFromModelKeyFunc(pathFunction).join('.');
}

export function nestedOnUpdateInput<T extends object>(
  nestedKeyPath: string,
  setForm: React.Dispatch<SetStateAction<T>>
) {
  return (key: string[], value: unknown) => {
    setForm((oldForm) => {
      return produce(oldForm, (draft) => {
        const newNestedObject = {
          ..._.get(draft, nestedKeyPath)
        };
        _.set(newNestedObject, key, value);

        _.set(draft, nestedKeyPath, newNestedObject);
      });
    });
  };
}
