type Listener<T> = (data: T) => void;

class EventEmitter<T> {
  private listeners: Listener<T>[];

  constructor() {
    this.listeners = [];
  }

  /**
   * Adds a listener callback to the manager.
   * @param listener The callback function to add.
   */
  addListener(listener: Listener<T>): void {
    this.listeners.push(listener);
  }

  /**
   * Removes a listener callback from the manager.
   * @param listener The callback function to remove.
   */
  removeListener(listener: Listener<T>): void {
    const index = this.listeners.indexOf(listener);
    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }

  /**
   * Notifies all listeners of an event.
   * @param event The event data to pass to the listeners.
   */
  emit(event: T): void {
    for (const listener of this.listeners) {
      listener(event);
    }
  }
}

export default EventEmitter;
