import React, { useCallback, useMemo, useState } from 'react';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import T from 'ecto-common/lib/lang/Language';
import {
  buttonListColumn,
  calculateDataTableMinHeight,
  checkboxColumn
} from 'ecto-common/lib/utils/dataTableUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import _ from 'lodash';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { AssignmentTypeEnum } from '../API/PresentationAPIGen';

export type Item = {
  id?: string;
  name?: string;
  priority?: number;
  nodeId?: string;
  type?: AssignmentTypeEnum;
};

export const topColumns = [
  {
    label: T.common.name,
    dataKey: 'name',
    idKey: 'id',
    linkColumn: true,
    dataFormatter: (value: string) => (
      <>
        <Icons.File /> {value}{' '}
      </>
    )
  }
];

export const canChangeItem = ({
  nodeId,
  canOnlyEditUnassignedTypes,
  item,
  isGlobalEditor
}: {
  nodeId: string;
  canOnlyEditUnassignedTypes?: boolean;
  item: Item;
  isGlobalEditor: boolean;
}) => {
  if (canOnlyEditUnassignedTypes) {
    return item.nodeId == null;
  }
  return (
    (nodeId == null || // We are in equipment type context
      item.nodeId === nodeId || // Item is assign to this node already
      item.nodeId == null) && // Item has not yet been assigned to this node
    (item.type !== AssignmentTypeEnum.Global || isGlobalEditor) // If the item is global, we can only edit it in global editor (isGlobalEditor)
  );
};

const isTypeSpecificNode = (nodeId: string) => (data: Item) => {
  return (
    (nodeId != null && data.nodeId != null && data.nodeId !== nodeId) ||
    data.type === AssignmentTypeEnum.Global
  );
};

export const mapPriority = ({
  nodeId,
  canOnlyEditUnassignedTypes,
  data,
  typeSpecificPriorityOffset
}: {
  nodeId: string;
  canOnlyEditUnassignedTypes?: boolean;
  data: Item[];
  /**
   * Type specific offset, for example tenant equipment types have 100, and global types have 1000 offset
   */
  typeSpecificPriorityOffset: number;
}) => {
  if (nodeId === null && !canOnlyEditUnassignedTypes) {
    // When we are in equipment type id selection.
    // each priority should step by typeSpecificPriorityOffset that way we can easily insert node specific items between them.
    return _.map(data, (selection, index) => ({
      ...selection,
      priority: typeSpecificPriorityOffset * (data.length - index)
    }));
  }

  const isTypeSpecific = (item: Item) =>
    (nodeId == null && item.nodeId != null && canOnlyEditUnassignedTypes) ||
    isTypeSpecificNode(nodeId)(item);

  const indexOfFirstTypeSpecific = data.findIndex(isTypeSpecific);
  let lastPriority =
    indexOfFirstTypeSpecific === -1
      ? // No type specific items in the list
        data.length - 1
      : // The first type specific priority and our first non type specific priority should be at least index specific offset apart
        // For example:
        //
        // Type specific item prio 100 at index 3
        // The first non type specific item should be prio 100 + 3 + 1 - 1 = 103  (the -1 due to the loop below) because there are tree items before the first type specific item
        // the array would be: [type1 (103), type2 (102), type3 (101), typeSpecific (100), ...]
        data[indexOfFirstTypeSpecific].priority + indexOfFirstTypeSpecific + 1;
  const result = _.map(data, (item) => {
    if (isTypeSpecific(item)) {
      lastPriority = item.priority;
      return item;
    }

    lastPriority -= 1;
    return {
      ...item,
      priority: lastPriority
    };
  });

  return result;
};

const ItemPrioritySelector = ({
  nodeId,
  canOnlyEditUnassignedTypes,
  items: _items,
  selectedData,
  onSelectedDataChanged,
  hasError,
  isLoading,
  isGlobalEditor,
  typeSpecificPriorityOffset = 100
}: {
  nodeId: string;
  canOnlyEditUnassignedTypes?: boolean;
  items: Item[];
  selectedData: Item[];
  onSelectedDataChanged: (items: Item[]) => void;
  hasError: boolean;
  isLoading: boolean;
  isGlobalEditor: boolean;
  typeSpecificPriorityOffset?: number;
}) => {
  const items = useMemo(() => _.keyBy(_items, 'id'), [_items]);

  const onDelete = useCallback(
    ({ id }: Item) => {
      if (
        canChangeItem({
          nodeId,
          canOnlyEditUnassignedTypes,
          item: items[id],
          isGlobalEditor
        })
      ) {
        onSelectedDataChanged(_.reject(selectedData, { id }));
      }
    },
    [
      canOnlyEditUnassignedTypes,
      isGlobalEditor,
      items,
      nodeId,
      onSelectedDataChanged,
      selectedData
    ]
  );

  const moveItemUp = useCallback(
    (_unused: Item, indexToMove: number) => {
      if (indexToMove > 0) {
        const newSelection = [...selectedData];
        newSelection.splice(
          indexToMove - 1,
          2,
          newSelection[indexToMove],
          newSelection[indexToMove - 1]
        );
        onSelectedDataChanged(
          mapPriority({
            nodeId,
            canOnlyEditUnassignedTypes,
            data: newSelection,
            typeSpecificPriorityOffset
          })
        );
      }
    },
    [
      canOnlyEditUnassignedTypes,
      nodeId,
      onSelectedDataChanged,
      selectedData,
      typeSpecificPriorityOffset
    ]
  );

  const moveItemDown = useCallback(
    (_unused: Item, indexToMove: number) => {
      if (indexToMove < selectedData.length - 1) {
        const newSelection = [...selectedData];
        newSelection.splice(
          indexToMove,
          2,
          newSelection[indexToMove + 1],
          newSelection[indexToMove]
        );
        onSelectedDataChanged(
          mapPriority({
            nodeId,
            canOnlyEditUnassignedTypes,
            data: newSelection,
            typeSpecificPriorityOffset
          })
        );
      }
    },
    [
      canOnlyEditUnassignedTypes,
      nodeId,
      onSelectedDataChanged,
      selectedData,
      typeSpecificPriorityOffset
    ]
  );

  const allTopColumns = useMemo(() => {
    return [
      ...topColumns,
      buttonListColumn<Item>([
        {
          icon: <Icons.NavigationArrowDown />,
          action: moveItemDown,
          enabled: (item) =>
            canChangeItem({
              nodeId,
              canOnlyEditUnassignedTypes,
              item,
              isGlobalEditor
            })
        },
        {
          icon: <Icons.NavigationArrowUp />,
          action: moveItemUp,
          enabled: (item) =>
            canChangeItem({
              nodeId,
              canOnlyEditUnassignedTypes,
              item,
              isGlobalEditor
            })
        },
        {
          icon: <Icons.Delete />,
          action: onDelete,
          enabled: (item) =>
            canChangeItem({
              nodeId,
              canOnlyEditUnassignedTypes,
              item,
              isGlobalEditor
            })
        }
      ])
    ];
  }, [
    canOnlyEditUnassignedTypes,
    isGlobalEditor,
    moveItemDown,
    moveItemUp,
    nodeId,
    onDelete
  ]);

  const onClickRow = useCallback(
    (item: Item) => {
      const selectedItem = selectedData?.find((data) => data.id === item.id);

      if (
        selectedItem == null ||
        canChangeItem({
          nodeId,
          canOnlyEditUnassignedTypes,
          item: selectedItem,
          isGlobalEditor
        })
      ) {
        onSelectedDataChanged(
          _.find(selectedData, (data) => data.id === item.id)
            ? _.reject(selectedData, (data) => data.id === item.id)
            : mapPriority({
                nodeId,
                canOnlyEditUnassignedTypes,
                data: [...(selectedData ?? []), item],
                typeSpecificPriorityOffset
              })
        );
      }
    },
    [
      canOnlyEditUnassignedTypes,
      isGlobalEditor,
      nodeId,
      onSelectedDataChanged,
      selectedData,
      typeSpecificPriorityOffset
    ]
  );

  const [searchString, setSearchString] = useState('');

  const onChangeSearchString = useCallback(
    (changeEvent: React.ChangeEvent<HTMLInputElement>) =>
      setSearchString(changeEvent.target.value),
    []
  );

  const filteredItems = useMemo(() => {
    const searchStringLowerCase = _.toLower(searchString);
    return _.filter(
      items,
      (item) => item && _.includes(_.toLower(item?.name), searchStringLowerCase)
    );
  }, [items, searchString]);

  const allColumns: DataTableColumnProps<Item>[] = useMemo(() => {
    return [
      checkboxColumn({
        rowIsChecked: (item) =>
          _.some(selectedData, (data) => data.id === item.id)
      }),
      {
        label: T.common.name,

        dataKey: 'name',
        idKey: 'id',
        linkColumn: true,
        dataFormatter: (value: string) => {
          return (
            <>
              <Icons.File /> {value}{' '}
            </>
          );
        }
      },
      {
        label: T.common.description,
        dataKey: 'description'
      }
    ];
  }, [selectedData]);

  return (
    <>
      <KeyValueLine>
        <KeyValueGeneric keyText={T.common.selecteditems}>
          <DataTable
            isLoading={isLoading}
            columns={allTopColumns}
            data={selectedData}
            noDataText={T.common.noitemsselected}
            minHeight={calculateDataTableMinHeight({
              pageSize: 1,
              withButtons: true,
              showNoticeHeaders: false
            })}
            showNoticeHeaders={false}
            hasError={hasError}
          />
        </KeyValueGeneric>
      </KeyValueLine>

      <TextInput
        placeholder={T.common.search.placeholder}
        value={searchString}
        icon={<Icons.Search />}
        onChange={onChangeSearchString}
      />

      <DataTable<Item>
        columns={allColumns}
        data={filteredItems}
        hasError={hasError}
        noDataText={T.common.datatable.nodata}
        isLoading={isLoading}
        onClickRow={onClickRow}
      />
    </>
  );
};

export default ItemPrioritySelector;
