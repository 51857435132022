import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore
} from 'react';
import GoogleMap, {
  LatLngBounds,
  MapsLibrary,
  onGoogleApiLoadedProps
} from 'google-maps-react-markers';

import GeocodingInputField from 'ecto-common/lib/GeocodingInputField/GeocodingInputField';
import { kml } from '@tmcw/togeojson';

import styles from 'ecto-common/lib/Map/Map.module.css';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';
import colors from 'ecto-common/lib/styles/variables/colors';

const googleMapsApiKey = 'AIzaSyAX6WMcSgrr8WkcqViU__ah91MIXC3sE7c';

const darkModeStyles = {
  backgroundColor: colors.darkBackgroundColor,
  styles: [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#38414e' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9ca5b3' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#746855' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#1f2835' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#f3d19c' }]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#2f3948' }]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#17263c' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#17263c' }]
    }
  ]
};

export type MapOnChangeValue = {
  bounds: LatLngBounds;
  center: [number, number];
  zoom: number;
};
/*
 * MapTypeStyle interface: https://developers.google.com/maps/documentation/javascript/reference/map#MapTypeStyle
 * Style reference: https://developers.google.com/maps/documentation/javascript/style-reference#style-elements
 */
const initialOptions = {
  streetViewControl: false,
  keyboardShortcuts: false,
  mapTypeControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ]
};

export type MapLocation = {
  street: string;
  lat: number;
  lng: number;
};

export type GoogleMapCoordinate = {
  lat: number;
  lng: number;
};

interface MapProps {
  children?: React.ReactNode;
  /**
   * The initial Map center.
   */
  center?: number[];
  /**
   * The initial Map zoom level. Required. Valid values: Integers between zero, and up to the supported maximum zoom level.
   */
  zoom?: number;
  /**
   * This event is fired when the user clicks on the map.
   * An ApiMouseEvent with properties for the clicked location is returned unless a place icon was clicked, in which case an IconMouseEvent with a placeId is returned.
   * IconMouseEvent and ApiMouseEvent are identical, except that IconMouseEvent has the placeId field.
   * The event can always be treated as an ApiMouseEvent when the placeId is not important.
   * The click event is not fired if a Marker or InfoWindow was clicked.
   */
  onClick?(): void;
  /**
   * This callback is called when the map instance has loaded. It is called with the map instance.
   */
  onMapsLoaded?(maps: onGoogleApiLoadedProps): void;

  /**
   * A event listener callback that executes on all "drag" events
   */
  onDrag?(): void;
  /**
   * A event listener callback that executes when "drag" events end
   */
  onDragEnd?(): void;
  /**
   * MapOptions object used to define the properties that can be set on a Map.
   */
  options?: object;
  /**
   * Activates the ability to search for addresses via google maps api
   */
  searchable?: boolean;
  /**
   * When "searchable" is active this callback method gets executed when a street address is selected from the search result and returns the street name.
   * Callback method for components that want to decide position of marker programmatically.
   * { street, lat, lng }
   */
  onSelectLocationFromSearch?(location: MapLocation, street: string): void;
  /**
   * Callback that changes the given map markers position if wanted.
   */
  setMarkerCenter?(center: number[]): void;
  /**
   * Sets the language of the map UI controls.
   */
  language?: string;
  /**
   * KML content to be displayed on the map.
   */
  kmlContent?: string;
}

const useKMLDataLayer = ({
  kmlContent,
  map,
  maps
}: {
  kmlContent?: string;
  map: google.maps.Map;
  maps: MapsLibrary;
}) => {
  const dataLayerRef = useRef<google.maps.Data | null>(null);

  const geoJson = useMemo(() => {
    const parser = new DOMParser();
    const kmlDocument = parser.parseFromString(kmlContent, 'application/xml');
    return kml(kmlDocument) as GeoJSON.FeatureCollection;
  }, [kmlContent]);

  useEffect(() => {
    if (dataLayerRef.current) {
      dataLayerRef.current.setMap(null);
      dataLayerRef.current = null;
    }

    if (geoJson == null || map == null || maps == null) {
      return;
    }
    const dataLayer = new maps.Data();
    dataLayer.addGeoJson(geoJson);

    // Apply styles from GeoJSON properties
    dataLayer.setStyle((feature) => {
      const color = (feature.getProperty('stroke') as string) || '#000000'; // Default to black
      const fillColor = (feature.getProperty('fill') as string) || '#FFFFFF'; // Default to white
      const strokeOpacity =
        (feature.getProperty('stroke-opacity') as number) || 1.0;
      const fillOpacity =
        (feature.getProperty('fill-opacity') as number) || 0.5;
      const strokeWeight = (feature.getProperty('stroke-width') as number) || 2;
      return {
        strokeColor: color,
        strokeOpacity,
        strokeWeight,
        fillColor,
        fillOpacity
      };
    });
    dataLayer.setMap(map);
    dataLayerRef.current = dataLayer;
  }, [geoJson, maps, map]);
};

/*
 * Documentation for google-maps-react-markers:
 * https://www.npmjs.com/package/google-maps-react-markers
 */
const Map = ({
  children,
  center,
  zoom,
  onClick,
  onMapsLoaded,
  onDrag,
  onDragEnd,
  options = {},
  searchable,
  setMarkerCenter,
  onSelectLocationFromSearch,
  language = 'en',
  kmlContent
}: MapProps) => {
  const [mapApi, setMapApi] = useState<google.maps.Map>(null);
  const [maps, setMaps] = useState<MapsLibrary>(null);
  const [streetSearch, setStreetSearch] = useState<string>(null);
  const [localCenter, setLocalCenter] = useState<number[]>(center);
  const darkModeEnabled = useSyncExternalStore(
    darkModeStore.subscribe,
    darkModeStore.getSnapshot
  );
  const onStreetSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setStreetSearch(event.target.value);
    }, []);

  const onGoogleApiLoaded = useCallback(
    (params: onGoogleApiLoadedProps) => {
      onMapsLoaded?.(params);

      setMapApi(params.map);
      setMaps(params.maps);
    },
    [onMapsLoaded]
  );

  const _options = useMemo(() => {
    return {
      ...initialOptions,
      ...options,
      ...(darkModeEnabled ? darkModeStyles : {})
    };
  }, [options, darkModeEnabled]);

  const onSelectedLocation = useCallback(
    (location: MapLocation) => {
      const { lat, lng, street } = location;
      const _center = [lat, lng];

      setStreetSearch(null);
      onSelectLocationFromSearch?.(location, street);
      setLocalCenter(_center);
      setMarkerCenter?.(_center);
    },
    [onSelectLocationFromSearch, setMarkerCenter]
  );

  useKMLDataLayer({ kmlContent, map: mapApi, maps });

  const curLat = center[0];
  const curLng = center[1];

  useEffect(() => {
    if (mapApi) {
      mapApi.setCenter({ lat: center[0], lng: center[1] });
    }
  }, [center, mapApi]);

  useEffect(() => {
    if (mapApi) {
      mapApi.setZoom(zoom);
    }
  }, [mapApi, zoom]);

  useEffect(() => {
    setLocalCenter([curLat, curLng]);
    if (mapApi) {
      mapApi.panTo({
        lat: curLat,
        lng: curLng
      });
    }
  }, [curLat, curLng, mapApi]);

  const defaultCenter = useMemo(() => {
    return {
      lat: localCenter[0],
      lng: localCenter[1]
    };
  }, [localCenter]);

  return (
    <div
      className={styles.container}
      onClick={onClick}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
    >
      {searchable && (
        <div className={styles.searchField}>
          <GeocodingInputField
            maps={maps}
            value={streetSearch}
            onChange={onStreetSearch}
            onSelectedLocation={onSelectedLocation}
            latitude={center[0]}
            longitude={center[1]}
          />
        </div>
      )}

      <GoogleMap
        apiKey={googleMapsApiKey}
        defaultCenter={defaultCenter}
        defaultZoom={zoom}
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={_options}
        externalApiParams={{ language }}
        loadingContent={<div />}
      >
        {children}
      </GoogleMap>
    </div>
  );
};

export default Map;
