import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import LoadingScreen from 'ecto-common/lib/LoadingScreen/LoadingScreen';
import T from 'ecto-common/lib/lang/Language';
import { ExportDataParams } from 'js/utils/routeConstants';
import APIGen from 'ecto-common/lib/API/APIGen';

const ExportDownloadPage = () => {
  const params = useParams<ExportDataParams>();
  const [redirect, setRedirect] = useState(false);

  const downloadUrlQuery = APIGen.Signals.downloadSignalExport.useQuery(
    {
      SignalExportId: params.exportId
    },
    { meta: { errorString: T.exportdownload.error } }
  );

  useEffect(() => {
    if (downloadUrlQuery.data?.downloadUrl) {
      window.location.href = downloadUrlQuery.data?.downloadUrl;
      setRedirect(true);
    }
  }, [downloadUrlQuery.data?.downloadUrl]);

  if (redirect) {
    return <Navigate to={'/'} />;
  }
  return downloadUrlQuery.isLoading && <LoadingScreen isLoading />;
};

export default ExportDownloadPage;
