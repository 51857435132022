/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ListResponseOfAssignedPictureModel {
  items?: AssignedPictureModel[];
}

export interface AssignedPictureModel {
  id?: string | null;
  /** @format int32 */
  priority?: number;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
  type?: AssignmentTypeEnum;
}

export enum AssignmentTypeEnum {
  Global = 'global',
  Tenant = 'tenant'
}

export interface ListResponseOfAssignedTenantDashboardModel {
  items?: AssignedTenantDashboardModel[];
}

export interface AssignedTenantDashboardModel {
  id?: string | null;
  /** @format int32 */
  priority?: number;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
  type?: AssignmentTypeEnum;
}

export interface ListResponseOfFileMetadataModel {
  items?: FileMetadataModel[];
}

export interface FileMetadataModel {
  id?: string | null;
  name?: string | null;
  /** @format int64 */
  size?: number;
  /** @format date-time */
  lastModified?: string | null;
}

export interface DownloadFileResponse {
  downloadUrl?: string | null;
}

export interface AssignPictureToNodeRequest {
  id?: string | null;
  /** @format int32 */
  priority?: number;
}

export interface AssignTenantDashboardToNodeRequest {
  id?: string | null;
  /** @format int32 */
  priority?: number;
}

export interface IHeaderDictionary {
  Item?: any[];
  /** @format int64 */
  ContentLength?: number | null;
  Accept?: any[];
  AcceptCharset?: any[];
  AcceptEncoding?: any[];
  AcceptLanguage?: any[];
  AcceptRanges?: any[];
  AccessControlAllowCredentials?: any[];
  AccessControlAllowHeaders?: any[];
  AccessControlAllowMethods?: any[];
  AccessControlAllowOrigin?: any[];
  AccessControlExposeHeaders?: any[];
  AccessControlMaxAge?: any[];
  AccessControlRequestHeaders?: any[];
  AccessControlRequestMethod?: any[];
  Age?: any[];
  Allow?: any[];
  AltSvc?: any[];
  Authorization?: any[];
  Baggage?: any[];
  CacheControl?: any[];
  Connection?: any[];
  ContentDisposition?: any[];
  ContentEncoding?: any[];
  ContentLanguage?: any[];
  ContentLocation?: any[];
  ContentMD5?: any[];
  ContentRange?: any[];
  ContentSecurityPolicy?: any[];
  ContentSecurityPolicyReportOnly?: any[];
  ContentType?: any[];
  CorrelationContext?: any[];
  Cookie?: any[];
  Date?: any[];
  ETag?: any[];
  Expires?: any[];
  Expect?: any[];
  From?: any[];
  GrpcAcceptEncoding?: any[];
  GrpcEncoding?: any[];
  GrpcMessage?: any[];
  GrpcStatus?: any[];
  GrpcTimeout?: any[];
  Host?: any[];
  KeepAlive?: any[];
  IfMatch?: any[];
  IfModifiedSince?: any[];
  IfNoneMatch?: any[];
  IfRange?: any[];
  IfUnmodifiedSince?: any[];
  LastModified?: any[];
  Link?: any[];
  Location?: any[];
  MaxForwards?: any[];
  Origin?: any[];
  Pragma?: any[];
  ProxyAuthenticate?: any[];
  ProxyAuthorization?: any[];
  ProxyConnection?: any[];
  Range?: any[];
  Referer?: any[];
  RetryAfter?: any[];
  RequestId?: any[];
  SecWebSocketAccept?: any[];
  SecWebSocketKey?: any[];
  SecWebSocketProtocol?: any[];
  SecWebSocketVersion?: any[];
  SecWebSocketExtensions?: any[];
  Server?: any[];
  SetCookie?: any[];
  StrictTransportSecurity?: any[];
  TE?: any[];
  Trailer?: any[];
  TransferEncoding?: any[];
  Translate?: any[];
  TraceParent?: any[];
  TraceState?: any[];
  Upgrade?: any[];
  UpgradeInsecureRequests?: any[];
  UserAgent?: any[];
  Vary?: any[];
  Via?: any[];
  Warning?: any[];
  WebSocketSubProtocols?: any[];
  WWWAuthenticate?: any[];
  XContentTypeOptions?: any[];
  XFrameOptions?: any[];
  XPoweredBy?: any[];
  XRequestedWith?: any[];
  XUACompatible?: any[];
  XXSSProtection?: any[];
}

export type ListResponseWithContinuationTokenOfPictureModel =
  ListResponseOfPictureModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfPictureModel {
  items?: PictureModel[];
}

export interface PictureModel {
  id?: string | null;
  tenant?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  allowedNodeTypes?: string[] | null;
}

export type ListResponseWithContinuationTokenOfSymbolModel =
  ListResponseOfSymbolModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfSymbolModel {
  items?: SymbolModel[];
}

export interface SymbolModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  states?: string[] | null;
  connections?: ConnectionModel[] | null;
}

export interface ConnectionModel {
  id?: string | null;
  /** @format float */
  x?: number;
  /** @format float */
  y?: number;
}

export type ListResponseWithContinuationTokenOfTenantDashboardModel =
  ListResponseOfTenantDashboardModel & {
    continuationToken?: string | null;
  };

export interface ListResponseOfTenantDashboardModel {
  items?: TenantDashboardModel[];
}

export interface TenantDashboardModel {
  id?: string | null;
  tenant?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  allowedNodeTypes?: string[] | null;
}

export interface DatapointDeleteDatapointSamplesParams {
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  id: string;
}

export interface NodesGetPicturesParams {
  nodeIds?: string[] | null;
}

export interface NodesGetDashboardsParams {
  nodeIds?: string[] | null;
}

export interface NodesGetFilesParams {
  prefix?: string | null;
  nodeId: string;
}

export interface NodesGetFileParams {
  openFile?: boolean;
  nodeId: string;
  id: string;
}

export interface PicturesListPicturesParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface SymbolsListSymbolsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface TenantDashboardsListTenantDashboardsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface VersionVersionParams {
  'api-version'?: string | null;
}

/**
 * @title Presentation API
 * @version v1
 * @baseUrl https://app-ec-presentation-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.PresentationAPIGen;

export interface DatapointDeleteDatapointSamplesPath {
  id: string;
}

export interface DatapointDeleteDatapointWithSamplesPath {
  id: string;
}

export interface NodesGetFilesPath {
  nodeId: string;
}

export interface NodesAddFilePath {
  nodeId: string;
}

export interface NodesGetFilePath {
  nodeId: string;
  id: string;
}

export interface NodesDeleteFilePath {
  nodeId: string;
  id: string;
}

export interface NodesAssignPicturesPath {
  nodeId: string;
}

export interface NodesAssignTenantDashboardsPath {
  nodeId: string;
}

export interface NodesUnassignPicturePath {
  nodeId: string;
  id: string;
}

export interface NodesUnassignDashboardPath {
  nodeId: string;
  id: string;
}

export interface PicturesGetPicturePath {
  id: string;
}

export interface PicturesUpdatePicturePath {
  id: string;
}

export interface PicturesDeletePicturePath {
  id: string;
}

export interface SymbolsGetSymbolPath {
  id: string;
}

export interface SymbolsUpdateSymbolPath {
  id: string;
}

export interface SymbolsDeleteSymbolPath {
  id: string;
}

export interface TenantDashboardsGetTenantDashboardPath {
  id: string;
}

export interface TenantDashboardsUpdateTenantDashboardPath {
  id: string;
}

export interface TenantDashboardsDeleteTenantDashboardPath {
  id: string;
}

const PresentationAPIGen = {
  Datapoint: {
    deleteDatapointSamples: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        DatapointDeleteDatapointSamplesParams,
        keyof DatapointDeleteDatapointSamplesPath
      >,
      void,
      ProblemDetails,
      DatapointDeleteDatapointSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointSamplesPath) =>
        `/api/v1/datapoints/${id}/samples`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteDatapointWithSamples: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      DatapointDeleteDatapointWithSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointWithSamplesPath) =>
        `/api/v1/datapoints/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Nodes: {
    getPictures: apiEndpointEmptyUsingQueryParams<
      NodesGetPicturesParams,
      ListResponseOfAssignedPictureModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/nodes/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getDashboards: apiEndpointEmptyUsingQueryParams<
      NodesGetDashboardsParams,
      ListResponseOfAssignedTenantDashboardModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/nodes/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getFiles: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<NodesGetFilesParams, keyof NodesGetFilesPath>,
      ListResponseOfFileMetadataModel,
      ProblemDetails,
      NodesGetFilesPath
    >(
      Method.GET,
      ({ nodeId }: NodesGetFilesPath) => `/api/v1/nodes/${nodeId}/files`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addFile: apiEndpointWithPath<
      {
        /** @format binary */
        file?: File;
      },
      FileMetadataModel,
      ProblemDetails,
      NodesAddFilePath
    >(
      Method.POST,
      ({ nodeId }: NodesAddFilePath) => `/api/v1/nodes/${nodeId}/files`,
      apiTitle,
      ContentType.FormData,
      ContentType.Json,
      ContentType.Json
    ),
    getFile: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<NodesGetFileParams, keyof NodesGetFilePath>,
      DownloadFileResponse,
      ProblemDetails,
      NodesGetFilePath
    >(
      Method.GET,
      ({ nodeId, id }: NodesGetFilePath) =>
        `/api/v1/nodes/${nodeId}/files/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteFile: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesDeleteFilePath
    >(
      Method.DELETE,
      ({ nodeId, id }: NodesDeleteFilePath) =>
        `/api/v1/nodes/${nodeId}/files/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    assignPictures: apiEndpointWithPath<
      AssignPictureToNodeRequest[],
      AssignedPictureModel[],
      ProblemDetails,
      NodesAssignPicturesPath
    >(
      Method.PUT,
      ({ nodeId }: NodesAssignPicturesPath) =>
        `/api/v1/nodes/${nodeId}/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    assignTenantDashboards: apiEndpointWithPath<
      AssignTenantDashboardToNodeRequest[],
      AssignedTenantDashboardModel[],
      ProblemDetails,
      NodesAssignTenantDashboardsPath
    >(
      Method.PUT,
      ({ nodeId }: NodesAssignTenantDashboardsPath) =>
        `/api/v1/nodes/${nodeId}/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    unassignPicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesUnassignPicturePath
    >(
      Method.DELETE,
      ({ nodeId, id }: NodesUnassignPicturePath) =>
        `/api/v1/nodes/${nodeId}/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    unassignDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesUnassignDashboardPath
    >(
      Method.DELETE,
      ({ nodeId, id }: NodesUnassignDashboardPath) =>
        `/api/v1/nodes/${nodeId}/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Pictures: {
    listPictures: apiEndpointEmptyUsingQueryParams<
      PicturesListPicturesParams,
      ListResponseWithContinuationTokenOfPictureModel,
      any
    >(
      Method.GET,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createPicture: apiEndpoint<PictureModel, PictureModel, ProblemDetails>(
      Method.POST,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getPicture: apiEndpointEmptyWithPath<
      PictureModel,
      ProblemDetails,
      PicturesGetPicturePath
    >(
      Method.GET,
      ({ id }: PicturesGetPicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updatePicture: apiEndpointWithPath<
      PictureModel,
      PictureModel,
      ProblemDetails,
      PicturesUpdatePicturePath
    >(
      Method.PUT,
      ({ id }: PicturesUpdatePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deletePicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      PicturesDeletePicturePath
    >(
      Method.DELETE,
      ({ id }: PicturesDeletePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Symbols: {
    listSymbols: apiEndpointEmptyUsingQueryParams<
      SymbolsListSymbolsParams,
      ListResponseWithContinuationTokenOfSymbolModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createSymbol: apiEndpoint<SymbolModel, SymbolModel, ProblemDetails>(
      Method.POST,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSymbol: apiEndpointEmptyWithPath<
      SymbolModel,
      ProblemDetails,
      SymbolsGetSymbolPath
    >(
      Method.GET,
      ({ id }: SymbolsGetSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateSymbol: apiEndpointWithPath<
      SymbolModel,
      SymbolModel,
      ProblemDetails,
      SymbolsUpdateSymbolPath
    >(
      Method.PUT,
      ({ id }: SymbolsUpdateSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteSymbol: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      SymbolsDeleteSymbolPath
    >(
      Method.DELETE,
      ({ id }: SymbolsDeleteSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  TenantDashboards: {
    listTenantDashboards: apiEndpointEmptyUsingQueryParams<
      TenantDashboardsListTenantDashboardsParams,
      ListResponseWithContinuationTokenOfTenantDashboardModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createTenantDashboard: apiEndpoint<
      TenantDashboardModel,
      TenantDashboardModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getTenantDashboard: apiEndpointEmptyWithPath<
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsGetTenantDashboardPath
    >(
      Method.GET,
      ({ id }: TenantDashboardsGetTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateTenantDashboard: apiEndpointWithPath<
      TenantDashboardModel,
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsUpdateTenantDashboardPath
    >(
      Method.PUT,
      ({ id }: TenantDashboardsUpdateTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteTenantDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantDashboardsDeleteTenantDashboardPath
    >(
      Method.DELETE,
      ({ id }: TenantDashboardsDeleteTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmptyUsingQueryParams<VersionVersionParams, File, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default PresentationAPIGen;
